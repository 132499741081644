<template>
    <div class="container-fluid d-flex pt-3 flex-wrap main-wrapper bg-dark h-100vh">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-12 col-12 p-0 mb-5">
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">
                                {{
                                    $store.state.language === "en"
                                        ? "Deposit"
                                        : "ငွေသွင်း"
                                }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <h6 class="heading-small">
                            {{
                                $store.state.language === "en"
                                    ? "Account information"
                                    : "အကောင့်ဆိုင်ရာ အချက်အလက်များ"
                            }}
                        </h6>
                        <span class="heading-small">{{
                                    $store.state.language === "en"
                                        ? "User's Balance"
                                        : "လက်ကျန်ငွေ"
                                }} - </span>
                        <span>
                          <b class="text-danger">{{ Number($store.state.amount).toLocaleString() }}</b>
                          ฿
                        </span>
                        <div class="pl-lg-4 mt-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <div class="d-flex col-12 flex-wrap p-0 mb-4">
                                            <div class="col-6 px-1 py-1" @click="bankSelection(106)">
                                                <div class="amount-cont py-2 text-left px-3 w-100" :class="bankId === 106 ? 'active' : ''">
                                                    <img src="/build/assets/img/banking/bangkokBank.jpeg" alt="" style="width: 30px; height: 30px; border-radius: 5px;">
                                                    <span class="ml-2">Bangkok Bank</span>
                                                </div>
                                            </div>
                                            
                                            <div class="col-6 px-1 py-1" @click="bankSelection(105)">
                                                <div class="amount-cont py-2 text-left px-3 w-100" :class="bankId === 105 ? 'active' : ''">
                                                    <img src="/build/assets/img/banking/bankOfThailand.jpeg" alt="" style="width: 30px; height: 30px; border-radius: 5px;">
                                                    <span class="ml-2">Bank of Thailand</span>
                                                </div>
                                            </div>
                                            
                                            <div class="col-6 px-1 py-1" @click="bankSelection(107)">
                                                <div class="amount-cont py-2 text-left px-3 w-100" :class="bankId === 107 ? 'active' : ''">
                                                    <img src="/build/assets/img/banking/kasikorn.png" alt="" style="width: 30px; height: 30px; border-radius: 5px;">
                                                    <span class="ml-2">Kasikorn Bank</span>
                                                </div>
                                            </div>

                                            <div class="col-6 px-1 py-1" @click="bankSelection(108)">
                                                <div class="amount-cont py-2 text-left px-3 w-100" :class="bankId === 108 ? 'active' : ''">
                                                    <img src="/build/assets/img/banking/krungthai.png" alt="" style="width: 30px; height: 30px; border-radius: 5px;">
                                                    <span class="ml-2">Krung Thai Bank</span>
                                                </div>
                                            </div>

                                            <div class="col-6 px-1 py-1" @click="bankSelection(110)">
                                                <div class="amount-cont py-2 text-left px-3 w-100" :class="bankId === 110 ? 'active' : ''">
                                                    <img src="/build/assets/img/banking/scb.jpeg" alt="" style="width: 30px; height: 30px; border-radius: 5px;">
                                                    <span class="ml-2">Siam Commercial</span>
                                                </div>
                                            </div>

                                            <div class="col-6 px-1 py-1" @click="bankSelection(109)">
                                                <div class="amount-cont py-2 text-left px-3 w-100" :class="bankId === 109 ? 'active' : ''">
                                                    <img src="/build/assets/img/banking/ttb.png" alt="" style="width: 50px; height: 30px; border-radius: 5px;">
                                                    <span class="ml-2">TMB Thanachart</span>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="text-danger">{{
                                            errors.account
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div
                                class="col-12 p-0 d-flex flex-wrap"
                                v-if="account.length !== 0"
                            >
                                <div class="col-lg-6 col-12 py-5">
                                    <h3>{{ account[0].name }}</h3>
                                    <div
                                        class="
                                            number-container
                                            d-flex
                                            py-3
                                            my-2
                                        "
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Account Name"
                                                : "အကောင့် အမည်"
                                        }}
                                        :
                                        <h3 class="m-0">
                                            &nbsp;{{ account[0].account_name }}

                                        </h3>
                                    </div>
                                    <div
                                        class="
                                            number-container
                                            d-flex
                                            py-3
                                            my-2
                                        "
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Number"
                                                : "အကောင့် နံပါတ်"
                                        }}
                                        :
                                        <h3 class="m-0" id="textToCopy">
                                            &nbsp;{{ account[0].number }}

                                        </h3>
                                        <span class="ml-5 text-danger badge badge-danger" @click="copyText">Copy</span>
                                    </div>
                                    <b class="text-sm">{{
                                        $store.state.language === "en"
                                            ? "Please charge amount to that account and save voucher screen shot and put that screenshot to file input."
                                            : "ဖော်ပြထားသောအကောင့်ကို ငွေလွဲပေးပါ။ ရရှိလာတဲ့ငွေပေးချေမူအောင်မြင်မူ ကို မှတ်တမ်းတင် (screenshot) ရိုက်ထားပါ။ ထိုမှတ်တမ်းတင်ပုံ (screenshot) ကို admin ဆီသို့ upload ၍ပို့ပေးပါ။"
                                    }}</b>
                                </div>
                            </div> -->
                        </div>
                        <hr class="my-4" />
                        <div class="pl-lg-4">
                            <div class="row">
                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label">{{
                                            $store.state.language === "en"
                                                ? "Screenshot"
                                                : "ငွေပေးချေမူ ဓာက်ပုံ"
                                        }}</label>

                                        <div class="custom-file d-flex">
                                            <van-uploader
                                                class="mx-auto"
                                                v-model="imageUploader"
                                                :max-count="1"
                                            />
                                        </div>
                                        <span class="text-danger">{{
                                            errors.image
                                        }}</span>
                                    </div>
                                </div> -->
                                <span class="text-danger text-xs pl-3 mb-2" v-if="!$store.state.authUser.bank_account_name && !$store.state.authUser.bank_account_name">{{
                                    $store.state.language === "en"
                                        ? "Entered Bank Account and Bank account you want to transfer must be the same."
                                        : "Entered Bank Account and Bank account you want to transfer must be the same."
                                }}</span>

                                <span class="text-danger text-xs pl-3 mb-2" v-else>{{
                                    $store.state.language === "en"
                                        ? "Please contact to your agent to edit Bank Info!"
                                        : "Please contact to your agent to edit Bank Info!"
                                }}</span>
                                
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Bank Username"
                                                    : "Bank Username"
                                            }}</label
                                        >
                                        <input
                                            type="text"
                                            id="input-email"
                                            class="form-control"
                                            disabled
                                            v-if="$store.state.authUser.bank_account_name"
                                            v-model="$store.state.authUser.bank_account_name"
                                        />

                                        <input
                                            type="text"
                                            id="input-email"
                                            class="form-control"
                                            v-else
                                            v-model="depositData.bank_account_name"
                                        />
                                        <span class="text-danger">{{
                                            errors.username
                                        }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Bank Account Number"
                                                    : "Bank Account Number"
                                            }}</label
                                        >
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            disabled
                                            v-if="$store.state.authUser.bank_account_number"
                                            v-model="$store.state.authUser.bank_account_number"
                                        />
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            v-else
                                            v-model="depositData.bank_account_number"
                                        />
                                        
                                        <span class="text-danger">{{
                                            errors.number
                                        }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Amount"
                                                    : "ငွေပမာဏ"
                                            }}</label
                                        >
                                        <span class="text-danger text-xs">{{
                                            $store.state.language === "en"
                                                ? " ( Minimum amount 300 )"
                                                : " ( အနည်းဆုံး 300 )"
                                        }}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            v-model="depositData.amount"
                                        />
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4">
                            <!-- <div class="form-group">
                                <label class="form-control-label">{{
                                    $store.state.language === "en"
                                        ? "Last Transaction Id 6"
                                        : "လုပ်ဆောင်မှုအမှတ်စဉ် (နောက်ဆုံး ၆ လုံး)"
                                }}</label>
                                <input
                                    type="number"
                                    id="input-email"
                                    class="form-control"
                                    v-model="depositData.remark"
                                />
                                <span class="text-danger">{{
                                    errors.remark
                                }}</span>

                            </div> -->
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="sendDepositData"
                                >
                                    <div v-if="!isConfirm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Confirm"
                                                : "အတည်ပြုမည်"
                                        }}
                                    </div>

                                    <Loading
                                        color="#ffffff"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      selected_account: {},
      accounts: [],
      account: [],
      imageUploader: [],
      depositData: {},
      errors: {},
      isConfirm: false,
      bankId: null,
    };
  },
  methods: {
    bankSelection(bankId) {
        // this.depositData.amount = 1000;
        console.log('hello')
        this.bankId = bankId;
        console.log(this.depositData)
    },
    ...mapActions(['fetchUser']),
    copyText() {
      // Get the text to copy
      const textToCopy = document.getElementById('textToCopy').innerText;

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();

      // Execute the 'copy' command
      document.execCommand('copy');

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      alert('You have copy Phone Number');
    },
    async fetchAccounts() {
      try {
        const res = await axios.get('/accounts', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.accounts = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async sendDepositData() {
      try {
        const authUser = await this.fetchUser();
        this.errors = {};
        if (!this.bankId) {
          this.errors.account = this.$store.state.language === 'en'
            ? 'Please pick bank type'
            : 'အကောင့်အမျိုးအစား ရွေးချယ်ပေးပါ။';
          return false;
        }
        if (!this.depositData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
          return false;
        }
        if (!this.depositData.bank_account_name && !this.$store.state.authUser.bank_account_name) {
          this.errors.username = this.$store.state.language === 'en'
            ? 'Please enter Bank Account Username'
            : 'Please enter Bank Account Username';
          return false;
        }
        if (!this.depositData.bank_account_number && !this.$store.state.authUser.bank_account_number) {
          this.errors.number = this.$store.state.language === 'en'
            ? 'Please enter Bank Account Number'
            : 'Please enter Bank Account Number';
          return false;
        }
        if (this.depositData.amount < 300) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter at least 300 Baht'
            : 'กรุณากรอกอย่างน้อย 300 บาท';
          return false;
        }
        this.isConfirm = true;
        this.depositData.bank_id = this.bankId;
        this.depositData.date = this.currentTime;
        const res = await axios.post('/deposit/bank/data', this.depositData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.depositData = {};
        window.open(res.data.data.p_url, "_self");
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.isConfirm = false;
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။',
        );
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  watch: {
    'selected_account.account_id': function () {
      this.account = this.accounts.filter(
        (account) => account.id === this.selected_account.account_id,
      );
    },
  },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  async mounted() {
    this.$router.push('/manual-deposit');
    // this.fetchAccounts();
    // const authUser = await this.fetchUser();
    // if (!authUser) {
    //   this.$router.push('/auth/login');
    // }
  },
};
</script>
<style scoped>
.amount-cont {
    border: 1px solid #ccced0;
    border-radius: 10px;
}

.amount-cont.active {
    background: #dee2e6;
    color: black;
    border: 1px solid 
}

.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: #e9ecef;
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
